// metabolic_statistics.js

export const calculateMetabolicStats = (data, xAxis, yAxis) => {
  if (data.some((row) => row["Calories Burn Rate (cal/min)"] !== undefined)) {
    return calculateCalibreStats(data, xAxis); // Pass xAxis for Calibre-specific calculations
  } else {
    return calculateStandardWeirStats(data, xAxis, yAxis); // Handle standard Cortex calculations
  }
};

// Function to calculate stats for standard Cortex data
const calculateStandardWeirStats = (data, xAxis, yAxis) => {
  let stats = {};

  if (yAxis.some((y) => y.value === "vo2kg")) {
    const vo2kgData = data
      .map((row) => parseFloat(row["vo2kg"]))
      .filter((val) => !isNaN(val));
    stats.maxVo2kg = Math.max(...vo2kgData);
    const maxVo2kgTimeIndex = vo2kgData.indexOf(stats.maxVo2kg);
    stats.maxVo2kgTime = data[maxVo2kgTimeIndex][xAxis];
  }

  // Add logic for Weir calculation (assuming rer, vo2, vco2 columns exist)
  const rerData = data
    .map((row) => parseFloat(row["rer"]))
    .filter((val) => !isNaN(val));
  const vo2Data = data
    .map((row) => parseFloat(row["vo2"]))
    .filter((val) => !isNaN(val));

  if (rerData.length && vo2Data.length) {
    const rerMean = rerData.reduce((a, b) => a + b, 0) / rerData.length;
    const vo2Mean = vo2Data.reduce((a, b) => a + b, 0) / vo2Data.length;
    const veCalc = rerMean * vo2Mean;

    stats.rerMean = rerMean;
    stats.weirCalc = Math.round(1440 * (3.941 * vo2Mean + 1.1 * veCalc));
    stats.weirModified = Math.round((3.941 * vo2Mean + 1.106 * veCalc) * 1440);
  }

  return stats;
};

// Function to calculate Calibre-specific stats
export const calculateCalibreStats = (data, xAxis) => {
  let stats = {};
  const burnRateValues = data
    .map((row) => parseFloat(row["Calories Burn Rate (cal/min)"]))
    .filter((val) => !isNaN(val));

  if (burnRateValues.length > 0) {
    stats.bmrCalibreAvg =
      (burnRateValues.reduce((a, b) => a + b, 0) / burnRateValues.length) *
      1440;
    stats.bmrCalibreHighLow =
      ((Math.max(...burnRateValues) + Math.min(...burnRateValues)) / 2) * 1440;
    stats.bmrHigh = Math.max(...burnRateValues) * 1440;
  }

  // Add VO2Max calculation for "Relative VO2 (ml/kg/min)"
  const vo2MaxValues = data
    .map((row) => parseFloat(row["Relative VO2 (ml/kg/min)"]))
    .filter((val) => !isNaN(val));

  if (vo2MaxValues.length > 0) {
    stats.vo2Max = Math.max(...vo2MaxValues);
    const vo2MaxTimeIndex = vo2MaxValues.indexOf(stats.vo2Max);
    stats.vo2MaxTime = data[vo2MaxTimeIndex][xAxis];
  }

  const rerValues = data
    .map((row) => parseFloat(row["RER"]))
    .filter((val) => !isNaN(val));
  const vo2Values = data
    .map((row) => parseFloat(row["VO2 (absolute, slpm)"]))
    .filter((val) => !isNaN(val));

  if (rerValues.length > 0 && vo2Values.length > 0) {
    const rerMean = rerValues.reduce((a, b) => a + b, 0) / rerValues.length;
    const vo2Mean = vo2Values.reduce((a, b) => a + b, 0) / vo2Values.length;
    const veCalc = rerMean * vo2Mean;

    stats.weirCalcOriginal = Math.round(
      1440 * (3.941 * vo2Mean + 1.1 * veCalc)
    );
    stats.weirModifiedOriginal = Math.round(
      (3.941 * vo2Mean + 1.106 * veCalc) * 1440
    );
  }

  return stats;
};
