import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const KineticsCard = ({ yAxisLabel, max, min, fastCount, slowCount, fastPercentage, slowPercentage }) => {
  return (
    <Card className="kinetics-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Kinetics Statistics for {yAxisLabel}
        </Typography>
        <Typography variant="body1">
          <strong>Max Value:</strong> {max}
        </Typography>
        <Typography variant="body1">
          <strong>Min Value:</strong> {min}
        </Typography>
        <Typography variant="body1">
          <strong>Fast Phase Count:</strong> {fastCount}
        </Typography>
        <Typography variant="body1">
          <strong>Slow Phase Count:</strong> {slowCount}
        </Typography>
        <Typography variant="body1">
          <strong>Fast Phase Percent:</strong> {fastPercentage}%
        </Typography>
        <Typography variant="body1">
          <strong>Slow Phase Percent:</strong> {slowPercentage}%
        </Typography>
      </CardContent>
    </Card>
  );
};

export default KineticsCard;
