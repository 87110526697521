import { Client, Account, Databases, Query } from "appwrite";

const client = new Client()
  .setEndpoint(import.meta.env.VITE_APPWRITE_ENDPOINT)
  .setProject(import.meta.env.VITE_APPWRITE_PROJECT_ID);

export const account = new Account(client);
export const databases = new Databases(client);

const auth = {
  login: async (email, password) => {
    try {
      const session = await account.createEmailPasswordSession(email, password);
      console.log("Session created:", session);
      return session;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  },

  getUser: async () => {
    try {
      const user = await account.get();
      console.log("User data from account.get():", user);
      return user;
    } catch (error) {
      console.error("Get user error:", error);
      throw error;
    }
  },

  getCompleteUserData: async (userId) => {
    try {
      console.log("Fetching user data for ID:", userId);

      // First, get user document
      const userResponse = await databases.listDocuments(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
        [Query.equal("userId", userId)]
      );

      console.log("User response:", userResponse);

      if (!userResponse.documents || userResponse.documents.length === 0) {
        throw new Error("User document not found");
      }

      const userData = userResponse.documents[0];
      console.log("Found user data:", userData);

      // Then get role document
      const roleResponse = await databases.listDocuments(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_ROLES_COLLECTION_ID,
        [Query.equal("roleId", userData.roleID)]
      );

      console.log("Role response:", roleResponse);

      if (!roleResponse.documents || roleResponse.documents.length === 0) {
        throw new Error("Role document not found");
      }

      const roleData = roleResponse.documents[0];

      // Return combined user data
      return {
        id: userId,
        name: userData.Name,
        organisation: userData.organisation,
        role: roleData.name,
        email: userData.email,
        permissions: roleData.permissions || [],
      };
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error; // Throw the actual error for better debugging
    }
  },

  logout: async () => {
    try {
      await account.deleteSession("current");
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  },
};

export { auth };
export default client;
