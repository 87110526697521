import React from 'react';
import './Footer.css';
import logoA from './images/A.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logoA} alt="Logo" className="footer-logo" />
        <p className="footer-text">
          © {currentYear} Andrew Usher Sport Science. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
