// src/components/Graph.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';

// Register plugins for zooming and data labels
Chart.register(zoomPlugin, ChartDataLabels);

const Graph = ({ data, options }) => {
  return (
    <div className="chart-container">
      <Line data={data} options={options} />
    </div>
  );
};

// Default export for use in other components
export default Graph;
