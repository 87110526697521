import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../lib/appwrite";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const session = await auth.getUser();

      if (session && session.$id) {
        try {
          const userData = await auth.getCompleteUserData(session.$id);
          console.log("Complete user data:", userData);
          setUser({
            ...session,
            ...userData,
          });
        } catch (error) {
          console.error("User configuration check failed:", error);
          await auth.logout();
          setUser(null);
          setError(
            "Your account needs to be configured. Please contact your administrator."
          );
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Auth check failed:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      setError(null);
      await auth.login(email, password);
      await checkUser();
      return true;
    } catch (error) {
      console.error("Login error:", error);
      if (error.code === 401) {
        setError("Invalid email or password");
      } else if (error.message.includes("not found")) {
        setError(
          "Your account is not properly configured. Please contact your administrator."
        );
      } else {
        setError(error.message || "Login failed. Please try again.");
      }
      throw error;
    }
  };

  const logout = async () => {
    try {
      await auth.logout();
      setUser(null);
      setError(null);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    error,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
