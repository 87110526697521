import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faChartLine,
  faSignOutAlt,
  faUsersCog, // Added for admin icon
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import "./Navbar.css";
import logo from "./images/A.png";

const Navbar = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  // Updated to use correct spelling 'organisation'
  const displayName = user ? `${user.name} | ${user.organisation}` : "User";

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <nav className="navbar">
      <ul className="navbar-links">
        <li className="nav-logo">
          <Link to="/" className="nav-link">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </li>
        {user && (
          <>
            <li>
              <Link to="/home" className="nav-link">
                <FontAwesomeIcon icon={faHome} /> <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/data-analysis" className="nav-link">
                <FontAwesomeIcon icon={faChartLine} />{" "}
                <span>Data Analysis</span>
              </Link>
            </li>
            <li>
              <Link to="/profile" className="nav-link">
                <FontAwesomeIcon icon={faUser} /> <span>{displayName}</span>
              </Link>
            </li>
            {/* <li>
              <span className="nav-link">
                <FontAwesomeIcon icon={faUser} /> <span>{displayName}</span>
              </span>
            </li> */}
            {/* Admin link - only show for users with Admin role */}
            {user.role === "Admin" && (
              <li>
                <Link to="/admin" className="nav-link">
                  <FontAwesomeIcon icon={faUsersCog} /> <span>User Admin</span>
                </Link>
              </li>
            )}
            <li>
              <button onClick={handleLogout} className="nav-link logout-button">
                <FontAwesomeIcon icon={faSignOutAlt} /> <span>Logout</span>
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
