// // import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import HomePage from "./components/HomePage";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
// import DataAnalysis from "./components/pages/DataAnalysis";
// import LoginPage from "./components/LoginPage";
// import ProtectedRoute from "./components/ProtectedRoute";
// import { DataProvider } from "./components/context/DataContext";
// import { AuthProvider } from "./hooks/useAuth";
// import UserAdmin from "./components/AdminPage";
// import UserProfile from "./components/UserPofile";
// import DevIndicator from "./components/DevIndicator";

// function App() {
//   return (
// <>
// <DevIndicator />
//     <AuthProvider>
//       <DataProvider>
//         <div className="app-container">
//           <Router>
//             <Navbar />
//             <div className="content-wrapper">
//               <Routes>
//                 {/* Public Routes */}
//                 <Route path="/" element={<Navigate to="/login" replace />} />
//                 <Route path="/login" element={<LoginPage />} />

//                 {/* Protected Routes */}
//                 <Route
//                   path="/home"
//                   element={
//                     <ProtectedRoute>
//                       <HomePage />
//                     </ProtectedRoute>
//                   }
//                 />
//                 <Route
//                   path="/data-analysis"
//                   element={
//                     <ProtectedRoute>
//                       <DataAnalysis />
//                     </ProtectedRoute>
//                   }
//                 />
//                 <Route
//                   path="/admin"
//                   element={
//                     <ProtectedRoute allowedRoles={["Admin"]}>
//                       <UserAdmin />
//                     </ProtectedRoute>
//                   }
//                 />
//                 <Route
//                   path="/profile"
//                   element={
//                     <ProtectedRoute>
//                       <UserProfile />
//                     </ProtectedRoute>
//                   }
//                 />

//                 {/* Catch-all route for 404s */}
//                 <Route path="*" element={<Navigate to="/home" replace />} />
//               </Routes>
//             </div>
//             <Footer />
//           </Router>
//         </div>
//       </DataProvider>
//     </AuthProvider>
//   );
// }

// export default App;
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import DataAnalysis from "./components/pages/DataAnalysis";
import LoginPage from "./components/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { DataProvider } from "./components/context/DataContext";
import { AuthProvider } from "./hooks/useAuth";
import UserAdmin from "./components/AdminPage";
import UserProfile from "./components/UserPofile";
import ErrorBoundary from "./components/ErrorBoundary";

// Dev Indicator Component
const DevIndicator = () => {
  if (import.meta.env.MODE !== "development") return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        background: "#ffeb3b",
        color: "#000",
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "12px",
        fontWeight: "bold",
        zIndex: 9999,
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      }}
    >
      DEV MODE - {import.meta.env.VITE_APPWRITE_PROJECT_ID.slice(-6)}
    </div>
  );
};

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <DataProvider>
          <div className="app-container">
            <Router>
              <ErrorBoundary>
                <Navbar />
                <div className="content-wrapper">
                  <Routes>
                    {/* Public Routes */}
                    <Route
                      path="/"
                      element={<Navigate to="/login" replace />}
                    />
                    <Route
                      path="/login"
                      element={
                        <ErrorBoundary>
                          <LoginPage />
                        </ErrorBoundary>
                      }
                    />

                    {/* Protected Routes */}
                    <Route
                      path="/home"
                      element={
                        <ProtectedRoute>
                          <ErrorBoundary>
                            <HomePage />
                          </ErrorBoundary>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/data-analysis"
                      element={
                        <ProtectedRoute>
                          <ErrorBoundary>
                            <DataAnalysis />
                          </ErrorBoundary>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/admin"
                      element={
                        <ProtectedRoute allowedRoles={["Admin"]}>
                          <ErrorBoundary>
                            <UserAdmin />
                          </ErrorBoundary>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <ErrorBoundary>
                            <UserProfile />
                          </ErrorBoundary>
                        </ProtectedRoute>
                      }
                    />

                    {/* Catch-all route for 404s */}
                    <Route
                      path="*"
                      element={
                        <ErrorBoundary>
                          <Navigate to="/home" replace />
                        </ErrorBoundary>
                      }
                    />
                  </Routes>
                </div>
                <Footer />
                <DevIndicator />
              </ErrorBoundary>
            </Router>
          </div>
        </DataProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;

// Add this CSS to your styles
// .app-container {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
// }

// .content-wrapper {
//   flex: 1;
//   padding: 20px;
//   background-color: #f5f5f5;
// }
