import React, { useState, useEffect } from "react";
import { ID } from "appwrite";
import { databases, account } from "../lib/appwrite";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState("create"); // 'create' or 'edit'
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [newUser, setNewUser] = useState({
    Name: "",
    email: "",
    password: "",
    organisation: "",
    roleID: "",
    active: true,
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await databases.listDocuments(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID
      );
      setUsers(response.documents);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to load users");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setNewUser({
      Name: "",
      email: "",
      password: "",
      organisation: "",
      roleID: "",
      active: true,
    });
    setSelectedUser(null);
    setDialogMode("create");
  };

  const handleOpenDialog = (mode = "create", user = null) => {
    setDialogMode(mode);
    setError(null);
    setSuccess(null);

    if (mode === "edit" && user) {
      setSelectedUser(user);
      setNewUser({
        Name: user.Name,
        email: user.email,
        organisation: user.organisation,
        roleID: user.roleID,
        active: user.active,
        password: "", // Don't populate password in edit mode
      });
    } else {
      resetForm();
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetForm();
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      const now = new Date().toISOString();

      if (dialogMode === "create") {
        // Create new user
        const createdAccount = await account.create(
          ID.unique(),
          newUser.email,
          newUser.password,
          newUser.Name
        );

        await databases.createDocument(
          import.meta.env.VITE_APPWRITE_DATABASE_ID,
          import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
          createdAccount.$id,
          {
            userId: createdAccount.$id,
            Name: newUser.Name,
            email: newUser.email,
            organisation: newUser.organisation,
            roleID: newUser.roleID,
            active: newUser.active,
            createdAt: now,
            updatedAt: now,
          }
        );

        setSuccess("User created successfully");
      } else {
        // Update existing user
        const updateData = {
          Name: newUser.Name,
          email: newUser.email,
          organisation: newUser.organisation,
          roleID: newUser.roleID,
          active: newUser.active,
          updatedAt: now,
        };

        // Only update password if a new one is provided
        if (newUser.password) {
          // Update password in Appwrite auth
          // Note: This might require additional setup in Appwrite
          // You may need to implement a custom function in your backend
          console.log("Password update functionality needs to be implemented");
        }

        await databases.updateDocument(
          import.meta.env.VITE_APPWRITE_DATABASE_ID,
          import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
          selectedUser.$id,
          updateData
        );

        setSuccess("User updated successfully");
      }

      handleCloseDialog();
      fetchUsers();
    } catch (error) {
      console.error("Error saving user:", error);
      setError(error.message || "Failed to save user");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    setLoading(true);
    try {
      await databases.deleteDocument(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
        userId
      );
      setSuccess("User deleted successfully");
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      setError("Failed to delete user");
    } finally {
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const renderUserDialog = () => (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {dialogMode === "create" ? "Add New User" : "Edit User"}
      </DialogTitle>
      <form onSubmit={handleCreateUser}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            required
            value={newUser.Name}
            onChange={(e) => setNewUser({ ...newUser, Name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            required
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            disabled={dialogMode === "edit"} // Email can't be changed after creation
          />
          <TextField
            margin="dense"
            label={
              dialogMode === "create"
                ? "Password"
                : "New Password (leave blank to keep current)"
            }
            type="password"
            fullWidth
            required={dialogMode === "create"}
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
            helperText={
              dialogMode === "create"
                ? "Password must be at least 8 characters long"
                : "Leave blank to keep current password"
            }
          />
          <TextField
            margin="dense"
            label="Organisation"
            fullWidth
            required
            value={newUser.organisation}
            onChange={(e) =>
              setNewUser({ ...newUser, organisation: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense" required>
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.roleID}
              onChange={(e) =>
                setNewUser({ ...newUser, roleID: e.target.value })
              }
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="RT">RT</MenuItem>
              <MenuItem value="Athlete">Athlete</MenuItem>
              <MenuItem value="Invited">Invited</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={newUser.active}
                onChange={(e) =>
                  setNewUser({ ...newUser, active: e.target.checked })
                }
                color="primary"
              />
            }
            label={`User Status: ${newUser.active ? "Active" : "Inactive"}`}
            style={{ marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              loading ||
              !newUser.Name ||
              !newUser.email ||
              (!newUser.password && dialogMode === "create") ||
              !newUser.organisation ||
              !newUser.roleID
            }
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : dialogMode === "create" ? (
              "Create"
            ) : (
              "Update"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  const renderDeleteConfirmDialog = () => (
    <Dialog
      open={Boolean(confirmDelete)}
      onClose={() => setConfirmDelete(false)}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
        <Button
          onClick={() => handleDeleteUser(confirmDelete)}
          color="error"
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (loading && users.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4" component="h1">
          User Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog("create")}
          startIcon={<AddIcon />}
        >
          Add New User
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert
          severity="success"
          sx={{ mb: 2 }}
          onClose={() => setSuccess(null)}
        >
          {success}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Organisation</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.$id}>
                <TableCell>{user.Name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.organisation}</TableCell>
                <TableCell>{user.roleID}</TableCell>
                <TableCell>
                  <Tooltip title={user.active ? "Active" : "Inactive"}>
                    <span
                      style={{
                        color: user.active ? "green" : "red",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {user.active ? (
                        <CheckIcon fontSize="small" />
                      ) : (
                        <CloseIcon fontSize="small" />
                      )}
                      {user.active ? "Active" : "Inactive"}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {new Date(user.createdAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell>
                  {new Date(user.updatedAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Edit User">
                    <IconButton
                      onClick={() => handleOpenDialog("edit", user)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton
                      onClick={() => setConfirmDelete(user.$id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {renderUserDialog()}
      {renderDeleteConfirmDialog()}
    </div>
  );
};

export default UserAdmin;
