import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../context/DataContext";
import Select from "react-select";
import "./DataAnalysis.css";
import KineticsCard from "../utils/KineticsCard";
import ChartComponent from "../utils/chart"; // Adjust the path if necessary

import { calculateMetabolicStats } from "../utils/metabolic_statistics";
import SigmoidalStatsDisplay from "../SigmoidalStatsDisplay";

import {
  calculateLinearRegression,
  calculatePolynomialRegression,
  calculateSigmoidalFit,
} from "../utils/regressionUtils";

import {
  Slider,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

const DataAnalysis = () => {
  // State variables
  const { dataFrames } = useContext(DataContext);
  const [selectedDataFrame, setSelectedDataFrame] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [xAxis, setXAxis] = useState("");
  const [yAxis, setYAxis] = useState([]);
  const [range, setRange] = useState([0, 100]);
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(100);
  const [showXAxisFromZero, setShowXAxisFromZero] = useState(false);
  const [stats, setStats] = useState({});
  const [showLinearRegression, setShowLinearRegression] = useState(false);
  const [showPolynomialRegression, setShowPolynomialRegression] =
    useState(false);
  const [polynomialDegree, setPolynomialDegree] = useState(2);
  const [showMovingAverage, setShowMovingAverage] = useState(false);
  const [movingAverageWindow, setMovingAverageWindow] = useState(5);
  const [showKinetics, setShowKinetics] = useState(false);
  const [regressionStats, setRegressionStats] = useState({});
  const [showSigmoidalFit, setShowSigmoidalFit] = useState(false);
  const [showPeakDetection, setShowPeakDetection] = useState(false);
  const [sigmoidalData, setSigmoidalData] = useState([]);
  const [editingSigmoidalCell, setEditingSigmoidalCell] = useState(null);
  const [peakCutoff, setPeakCutoff] = useState(0);
  const [peakData, setPeakData] = useState({});
  const [peakInfo, setPeakInfo] = useState([]);
  const [regionsOfInterest, setRegionsOfInterest] = useState([]);
  const [kineticsStats, setKineticsStats] = useState({});
  const [showMetabolicStats, setShowMetabolicStats] = useState(false);
  const [metabolicStats, setMetabolicStats] = useState(null);
  const [linearRegressions, setLinearRegressions] = useState([]);
  const [editingCell, setEditingCell] = useState(null);

  const SigmoidalParametersTable = ({ sigmoidalData }) => {
    return (
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Max</TableCell>
              <TableCell>Min</TableCell>
              <TableCell>Difference</TableCell>
              <TableCell>L</TableCell>
              <TableCell>x₀</TableCell>
              <TableCell>k</TableCell>
              <TableCell>R²</TableCell>
              <TableCell>AUC</TableCell>
              <TableCell>Tmax</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Y-Axis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sigmoidalData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.startTime}</TableCell>
                <TableCell>{row.endTime}</TableCell>
                <TableCell>{row.max.toFixed(4)}</TableCell>
                <TableCell>{row.min.toFixed(4)}</TableCell>
                <TableCell>{row.difference.toFixed(4)}</TableCell>
                <TableCell>{row.L.toFixed(4)}</TableCell>
                <TableCell>{row.x0.toFixed(4)}</TableCell>
                <TableCell>{row.k.toFixed(4)}</TableCell>
                <TableCell>{row.rSquared.toFixed(4)}</TableCell>
                <TableCell>{row.auc.toFixed(4)}</TableCell>
                <TableCell>{row.tmax}</TableCell>
                <TableCell>{row.model}</TableCell>
                <TableCell>{row.yAxis}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  useEffect(() => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      const data = dataFrames[selectedDataFrame];
      setOriginalData(data);
      setDisplayData(data);
      setRange([0, data.length - 1]);
      setXAxis(Object.keys(data[0])[0]);
      setYAxis([
        { value: Object.keys(data[0])[1], label: Object.keys(data[0])[1] },
      ]);
      setStartValue(0);
      setEndValue(data.length - 1);
      calculateStats(data);
    }
  }, [selectedDataFrame, dataFrames]);

  // Update stats when yAxis is selected or changed
  useEffect(() => {
    if (yAxis.length > 0) {
      calculateStats(displayData);
    }
  }, [yAxis, displayData]);

  //

  const calculateStats = (data) => {
    const newStats = {};
    yAxis.forEach((yCol) => {
      const yValues = data.map((row) => parseFloat(row[yCol.value]));
      const dataToAnalyze = showMovingAverage
        ? calculateMovingAverage(yValues, movingAverageWindow)
        : yValues;

      const max = Math.max(...dataToAnalyze).toFixed(2);
      const min = Math.min(...dataToAnalyze).toFixed(2);
      const mean = (
        dataToAnalyze.reduce((a, b) => a + b, 0) / dataToAnalyze.length
      ).toFixed(2);
      const difference = (max - min).toFixed(2);

      // Calculate Mean Absolute Deviation (MAD)
      const meanValue =
        dataToAnalyze.reduce((a, b) => a + b, 0) / dataToAnalyze.length;
      const mad = (
        dataToAnalyze.reduce((sum, val) => sum + Math.abs(val - meanValue), 0) /
        dataToAnalyze.length
      ).toFixed(2);

      newStats[yCol.label] = { max, min, mean, difference, mad };
    });
    setStats(newStats);
  };

  // Metabolic Inside DataAnalysis component

  useEffect(() => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      const data = dataFrames[selectedDataFrame];
      setOriginalData(data);

      // Set xAxis and yAxis only if they are not already set to avoid resetting dropdowns
      if (!xAxis) {
        setXAxis(Object.keys(data[0])[0]);
      }
      if (yAxis.length === 0) {
        setYAxis([
          { value: Object.keys(data[0])[1], label: Object.keys(data[0])[1] },
        ]);
      }

      // Update displayData and calculate metabolic stats with current smoothing and range
      const slicedData = data.slice(startValue, endValue + 1);
      setDisplayData(slicedData);
      updateMetabolicStats(slicedData);
    }
  }, [
    selectedDataFrame,
    dataFrames,
    startValue,
    endValue,
    showMovingAverage,
    movingAverageWindow,
  ]);

  const updateMetabolicStats = (data) => {
    // Apply moving average to relevant columns, if enabled
    const processedData = data.map((row, index) => {
      const smoothedRow = { ...row };
      const columnsToSmooth = [
        "vo2",
        "vco2",
        "vo2kg",
        "rer",
        "Calories Burn Rate (cal/min)",
        "Relative VO2 (ml/kg/min)", // Add VO2 max column here
      ];
      columnsToSmooth.forEach((col) => {
        if (row[col] !== undefined) {
          const colValues = data
            .map((d) => parseFloat(d[col]))
            .filter((val) => !isNaN(val));
          const smoothedValues = calculateMovingAverage(
            colValues,
            movingAverageWindow
          );
          smoothedRow[col] = smoothedValues[index];
        }
      });
      return smoothedRow;
    });

    // Calculate stats with the processed (sliced/smoothed) data
    const stats = calculateMetabolicStats(processedData, xAxis, yAxis);
    setMetabolicStats(stats);
  };

  useEffect(() => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      const data = dataFrames[selectedDataFrame];
      setOriginalData(data);

      // Initialize xAxis and yAxis only if they aren't already set
      if (!xAxis) {
        setXAxis(Object.keys(data[0])[0]);
      }
      if (yAxis.length === 0) {
        setYAxis([
          { value: Object.keys(data[0])[1], label: Object.keys(data[0])[1] },
        ]);
      }

      // Update displayData and calculate metabolic stats with current settings
      const slicedData = data.slice(startValue, endValue + 1);
      setDisplayData(slicedData);
      updateMetabolicStats(slicedData);
    }
  }, [
    selectedDataFrame,
    dataFrames,
    startValue,
    endValue,
    showMovingAverage,
    movingAverageWindow,
  ]);

  // Kinetics componenet
  const calculateKineticsStats = (data) => {
    if (!showKinetics || yAxis.length === 0) return;

    const newKineticsStats = {};

    yAxis.forEach((yCol) => {
      const yValuesRaw = data.map((row) => parseFloat(row[yCol.value]));
      const yValues = showMovingAverage
        ? calculateMovingAverage(yValuesRaw, movingAverageWindow)
        : yValuesRaw;

      const max = Math.max(...yValues).toFixed(2);
      const min = Math.min(...yValues).toFixed(2);

      // Check if polynomial regression data is available
      const trend = regressionStats[yCol.label]?.polynomial?.trend;

      if (!trend) {
        console.warn(`No polynomial regression trend data for ${yCol.label}`);
        return;
      }

      // Calculate fast and slow points based on trend
      let fastCount = 0;
      let slowCount = 0;

      yValues.forEach((value, index) => {
        if (index > 0) {
          if (trend === "up") {
            if (value > yValues[index - 1]) {
              fastCount++;
            } else {
              slowCount++;
            }
          } else if (trend === "down") {
            if (value < yValues[index - 1]) {
              fastCount++;
            } else {
              slowCount++;
            }
          } else {
            console.warn(`Unknown trend '${trend}' for ${yCol.label}`);
          }
        }
      });

      const totalPoints = fastCount + slowCount;
      const fastPercentage =
        totalPoints > 0 ? ((fastCount / totalPoints) * 100).toFixed(2) : "0.00";
      const slowPercentage =
        totalPoints > 0 ? ((slowCount / totalPoints) * 100).toFixed(2) : "0.00";

      newKineticsStats[yCol.label] = {
        max,
        min,
        fastCount,
        slowCount,
        fastPercentage,
        slowPercentage,
      };
    });

    setKineticsStats(newKineticsStats);
  };

  useEffect(() => {
    if (yAxis && yAxis.length > 0) {
      const filteredData = originalData.slice(range[0], range[1] + 1);
      setDisplayData(filteredData);
      calculateStats(filteredData);

      let updatedRegressionStats = {};

      if (
        showLinearRegression ||
        showPolynomialRegression ||
        showSigmoidalFit
      ) {
        yAxis.forEach((yCol) => {
          // Get base values and create normalized values at the start
          const xValues = filteredData.map((row) => parseFloat(row[xAxis]));
          const normalizedXValues = xValues.map((x) => x - xValues[0]); // Create normalized values here
          let yValues = filteredData.map((row) => parseFloat(row[yCol.value]));

          // Apply moving average if enabled
          if (showMovingAverage) {
            yValues = calculateMovingAverage(yValues, movingAverageWindow);
          }

          let regressionResults = {};

          try {
            // Linear Regression
            if (showLinearRegression) {
              const linearResult = calculateLinearRegression(
                showXAxisFromZero ? normalizedXValues : xValues,
                yValues
              );
              if (linearResult) {
                regressionResults.linear = linearResult;
              }
            }

            // Polynomial Regression
            if (showPolynomialRegression) {
              const polyResult = calculatePolynomialRegression(
                showXAxisFromZero ? normalizedXValues : xValues,
                yValues,
                polynomialDegree
              );
              if (polyResult) {
                regressionResults.polynomial = polyResult;
              }
            }

            if (showSigmoidalFit) {
              yAxis.forEach((yCol) => {
                // Get the raw data within the selected range
                const xValues = filteredData.map((row) =>
                  parseFloat(row[xAxis])
                );
                let yValues = filteredData.map((row) =>
                  parseFloat(row[yCol.value])
                );

                if (showMovingAverage) {
                  yValues = calculateMovingAverage(
                    yValues,
                    movingAverageWindow
                  );
                }

                // Calculate time offsets based on the actual range values
                const rangeStartTime = xValues[0];
                const rangeEndTime = xValues[xValues.length - 1];

                // Store time reference
                const timeReference = {
                  start: rangeStartTime,
                  end: rangeEndTime,
                  duration: rangeEndTime - rangeStartTime,
                };

                // For calculations, always use normalized time starting from 0
                const normalizedXValues = xValues.map(
                  (x) => x - rangeStartTime
                );

                const sigmoidResult = calculateSigmoidalFit(
                  normalizedXValues,
                  yValues
                );

                if (sigmoidResult) {
                  // Store the time references and create display data
                  sigmoidResult.timeReference = timeReference;
                  sigmoidResult.modelFit.displayData =
                    sigmoidResult.modelFit.predictions.map((pred, i) => ({
                      originalTime: xValues[i],
                      value: pred,
                    }));

                  regressionResults.sigmoidal = sigmoidResult;
                }
              });
            }
          } catch (error) {
            console.error(
              `Error calculating regression for ${yCol.label}:`,
              error
            );
          }

          if (Object.keys(regressionResults).length > 0) {
            updatedRegressionStats[yCol.label] = regressionResults;
          } else {
            console.warn(`No regression results for ${yCol.label}`);
          }
        });

        setRegressionStats(updatedRegressionStats);
      }

      if (showPeakDetection) {
        detectPeaksInData(filteredData);
      } else {
        setPeakData({});
      }

      if (showKinetics) {
        calculateKineticsStats(filteredData);
      }
    }
  }, [
    range,
    originalData,
    yAxis,
    showLinearRegression,
    showPolynomialRegression,
    showMovingAverage,
    movingAverageWindow,
    polynomialDegree,
    xAxis,
    showKinetics,
    showSigmoidalFit,
    showPeakDetection,
    peakCutoff,
    showXAxisFromZero,
  ]);

  const handleSigmoidalCellEdit = (id, field, value) => {
    setSigmoidalData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const deleteSigmoidalRow = (id) => {
    setSigmoidalData((prevData) => prevData.filter((row) => row.id !== id));
  };

  const exportSigmoidalToCSV = () => {
    const headers = [
      "Variable",
      "Start Time",
      "End Time",
      "Max",
      "Min",
      "Difference",
      "L",
      "x₀",
      "k",
      "R²",
      "AUC",
      "Tmax",
      "Model",
    ];

    const csvData = sigmoidalData.map((row) =>
      [
        row.yAxis,
        row.startTime,
        row.endTime,
        row.max,
        row.min,
        row.difference,
        row.L,
        row.x0,
        row.k,
        row.rSquared,
        row.auc,
        row.tmax,
        row.model,
      ].join(",")
    );

    const csvContent = [headers.join(","), ...csvData].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "sigmoidal_fits.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Handle Sigmoidal Fit
  // const handleStoreSigmoidalData = () => {
  //   if (!xAxis || yAxis.length === 0) return;

  //   yAxis.forEach((yCol) => {
  //     const xValues = displayData.map((row) => parseFloat(row[xAxis]));
  //     const yValues = displayData.map((row) => parseFloat(row[yCol.value]));

  //     const sigmoidalFit = calculateSigmoidalFit(xValues, yValues);

  //     if (sigmoidalFit) {
  //       const newSigmoidalData = {
  //         id: `${Date.now()}-${Math.random()}`, // Add unique identifier
  //         startTime: startValue,
  //         endTime: endValue,
  //         max: sigmoidalFit.max,
  //         min: sigmoidalFit.min,
  //         difference: sigmoidalFit.max - sigmoidalFit.min,
  //         L: sigmoidalFit.L,
  //         x0: sigmoidalFit.x0,
  //         k: sigmoidalFit.k,
  //         rSquared: sigmoidalFit.rSquared,
  //         auc: sigmoidalFit.auc,
  //         tmax: sigmoidalFit.tmax,
  //         model: "Logistic",
  //         yAxis: yCol.label,
  //       };

  //       setSigmoidalData((prev) => [...prev, newSigmoidalData]);
  //     }
  //   });
  // };

  const handleStoreSigmoidalData = () => {
    if (!xAxis || yAxis.length === 0) return;

    yAxis.forEach((yCol) => {
      const sigmoidalResult = regressionStats[yCol.label]?.sigmoidal;

      if (sigmoidalResult) {
        const newSigmoidalData = {
          id: `${Date.now()}-${Math.random()}`,
          yAxis: yCol.label,
          startTime: startValue,
          endTime: endValue,
          max: sigmoidalResult.max,
          min: sigmoidalResult.min,
          difference: sigmoidalResult.max - sigmoidalResult.min,
          L: sigmoidalResult.L,
          x0: sigmoidalResult.x0,
          k: sigmoidalResult.k,
          rSquared: sigmoidalResult.rSquared,
          auc: sigmoidalResult.auc,
          tmax: sigmoidalResult.tmax,
          model: "Logistic",
          processing: {
            smoothed: showMovingAverage,
            windowSize: showMovingAverage ? movingAverageWindow : null,
            normalizedTime: showXAxisFromZero,
            timeOffset: sigmoidalResult.timeReference?.start || 0,
          },
        };

        setSigmoidalData((prev) => [...prev, newSigmoidalData]);
      }
    });
  };

  const calculateMovingAverage = (data, windowSize) => {
    return data.map((_, index, arr) => {
      const start = Math.max(0, index - Math.floor(windowSize / 2));
      const end = Math.min(arr.length, index + Math.floor(windowSize / 2) + 1);
      const windowData = arr.slice(start, end);
      return windowData.reduce((sum, val) => sum + val, 0) / windowData.length;
    });
  };

  const detectPeaksInData = (data) => {
    const newPeakData = {};
    const newPeakInfo = [];

    yAxis.forEach((yCol) => {
      const xValues = data.map((row) => parseFloat(row[xAxis]));
      const yValuesRaw = data.map((row) => parseFloat(row[yCol.value]));
      const yValues = showMovingAverage
        ? calculateMovingAverage(yValuesRaw, movingAverageWindow)
        : yValuesRaw;

      const peaks = detectPeaks(xValues, yValues, peakCutoff);
      newPeakData[yCol.label] = peaks;

      // Calculate peak information for each peak
      let previousPeakValue = null;
      let previousPeakTime = null;

      peaks.forEach((peak) => {
        const peakTime = peak.x;
        const peakValue = peak.y;

        // Calculate percentage change from the previous peak
        let percentChange = null;
        if (previousPeakValue !== null) {
          percentChange =
            ((peakValue - previousPeakValue) / previousPeakValue) * 100;
        }

        // Calculate time difference from the previous peak
        let timeDifference = null;
        if (previousPeakTime !== null) {
          timeDifference = peakTime - previousPeakTime;
        }

        newPeakInfo.push({
          yAxis: yCol.label,
          time: peakTime,
          value: peakValue,
          percentChange:
            percentChange !== null ? percentChange.toFixed(2) : "N/A",
          timeDifference:
            timeDifference !== null ? timeDifference.toFixed(2) : "N/A",
        });

        previousPeakValue = peakValue;
        previousPeakTime = peakTime;
      });
    });

    setPeakData(newPeakData);
    setPeakInfo(newPeakInfo);
  };

  const detectPeaks = (xValues, yValues, cutoff) => {
    const peaks = [];
    for (let i = 1; i < yValues.length - 1; i++) {
      if (
        yValues[i] > yValues[i - 1] &&
        yValues[i] > yValues[i + 1] &&
        yValues[i] > cutoff
      ) {
        peaks.push({ x: xValues[i], y: yValues[i] });
      }
    }
    return peaks;
  };

  const columns = originalData.length > 0 ? Object.keys(originalData[0]) : [];
  const options = columns.map((col) => ({ value: col, label: col }));

  const handleRangeChange = (e, newValue) => {
    setRange(newValue);
    setStartValue(newValue[0]);
    setEndValue(newValue[1]);
  };

  const handleFineTuneChange = (index, value) => {
    if (value >= 0 && value <= originalData.length - 1) {
      const newRange = [...range];
      newRange[index] = value;
      setRange(newRange);
      if (index === 0) setStartValue(value);
      else setEndValue(value);
    }
  };

  const chartData = {
    labels: displayData.map((row) => parseFloat(row[xAxis])),
    datasets: [
      // Kinetics dataset
      ...(showKinetics
        ? yAxis
            .map((yCol, index) => {
              const yValuesRaw = displayData.map((row) =>
                parseFloat(row[yCol.value])
              );
              const xValues = displayData.map((row) => parseFloat(row[xAxis]));
              const yValues = showMovingAverage
                ? calculateMovingAverage(yValuesRaw, movingAverageWindow)
                : yValuesRaw;
              const polynomialPredictions =
                regressionStats[yCol.label]?.polynomial?.predictions;
              const trend = regressionStats[yCol.label]?.polynomial?.trend;

              if (!polynomialPredictions || !trend) {
                console.warn(`No kinetics data for ${yCol.label}`);
                return null;
              }

              // Determine point colors based on over/under the regression line and trend direction
              const pointBackgroundColors = yValues.map((value, i) => {
                if (polynomialPredictions[i] === undefined) return "gray"; // Default color if no prediction
                if (trend === "up") {
                  return value > polynomialPredictions[i] ? "green" : "red";
                } else {
                  return value < polynomialPredictions[i] ? "green" : "red";
                }
              });

              return {
                type: "scatter",
                label: `${yCol.label} - Kinetics`,
                data: xValues.map((x, i) => ({ x, y: yValues[i] })),
                pointBackgroundColor: pointBackgroundColors,
                pointBorderColor: pointBackgroundColors,
                showLine: false,
                yAxisID: `y-axis-${index}`,
                order: 1,
              };
            })
            .filter(Boolean)
        : []),

      // Polynomial regression line
      ...(showPolynomialRegression || showKinetics
        ? yAxis
            .map((yCol, index) => {
              const xValues = displayData.map((row) => parseFloat(row[xAxis]));
              const polynomialPredictions =
                regressionStats[yCol.label]?.polynomial?.predictions;

              if (
                !polynomialPredictions ||
                polynomialPredictions.length === 0
              ) {
                console.warn(`No polynomial regression data for ${yCol.label}`);
                return null;
              }

              return {
                type: "line",
                label: `${yCol.label} - Polynomial Regression (Degree ${polynomialDegree})`,
                data: xValues.map((x, i) => ({
                  x,
                  y: polynomialPredictions[i],
                })),
                borderColor: "black",
                borderWidth: 2,
                pointRadius: 0,
                yAxisID: `y-axis-${index}`,
                order: 2,
                fill: false,
                showLine: true,
              };
            })
            .filter(Boolean)
        : []),

      // Sigmoidal fit line

      // In your chartData.datasets array, update the sigmoidal section:
      // In chartData.datasets array
      // In chartData.datasets array
      ...(showSigmoidalFit
        ? yAxis
            .map((yCol, index) => {
              const sigmoidalFit = regressionStats[yCol.label]?.sigmoidal;
              if (!sigmoidalFit?.modelFit?.displayData) {
                return null;
              }

              const timeStart = sigmoidalFit.timeReference.start;

              return {
                type: "line",
                label: `${yCol.label} - Sigmoidal Fit`,
                data: sigmoidalFit.modelFit.displayData.map((point) => ({
                  x: showXAxisFromZero
                    ? point.originalTime - timeStart
                    : point.originalTime,
                  y: point.value,
                })),
                borderColor: "purple",
                borderWidth: 2,
                pointRadius: 0,
                yAxisID: `y-axis-${index}`,
                order: 3,
                fill: false,
              };
            })
            .filter(Boolean)
        : []),

      // Linear regression line
      ...(showLinearRegression
        ? yAxis
            .map((yCol, index) => {
              const xValues = displayData.map((row) => parseFloat(row[xAxis]));
              const regressionLine =
                regressionStats[yCol.label]?.linear?.predictions;

              if (!regressionLine || regressionLine.length === 0) {
                console.warn(`No linear regression data for ${yCol.label}`);
                return null;
              }

              return {
                type: "line",
                label: `${yCol.label} - Linear Regression`,
                data: xValues.map((x, i) => ({ x, y: regressionLine[i] })),
                borderColor: "blue",
                borderWidth: 2,
                pointRadius: 0,
                borderDash: [5, 5],
                yAxisID: `y-axis-${index}`,
                order: 4,
                fill: false,
                showLine: true,
              };
            })
            .filter(Boolean)
        : []),

      // Original data line (if Kinetics is not checked)
      ...(!showKinetics
        ? yAxis.map((yCol, index) => {
            const yValuesRaw = displayData.map((row) =>
              parseFloat(row[yCol.value])
            );
            const yValues = showMovingAverage
              ? calculateMovingAverage(yValuesRaw, movingAverageWindow)
              : yValuesRaw;
            const xValues = displayData.map((row) => parseFloat(row[xAxis]));

            // Filter out invalid data points
            const dataPoints = xValues.reduce((acc, x, i) => {
              const yValue = yValues[i];
              if (
                typeof x === "number" &&
                !isNaN(x) &&
                typeof yValue === "number" &&
                !isNaN(yValue)
              ) {
                acc.push({ x: x, y: yValue });
              }
              return acc;
            }, []);

            return {
              type: "line",
              label: showMovingAverage
                ? yCol.label + " (Moving Avg)"
                : yCol.label,
              data: dataPoints,
              borderWidth: 2,
              pointRadius: 0,
              yAxisID: `y-axis-${index}`,
              order: 5,
              borderColor: `hsl(${index * 60}, 70%, 50%)`,
              fill: false,
              showLine: true,
            };
          })
        : []),

      // Peaks dataset
      ...(showPeakDetection
        ? yAxis
            .map((yCol, index) => {
              const peaks = peakData[yCol.label] || [];
              if (peaks.length === 0) return null;

              return {
                type: "scatter",
                label: `${yCol.label} Peaks`,
                data: peaks,
                pointBackgroundColor: "red",
                pointBorderColor: "black",
                pointRadius: 5,
                yAxisID: `y-axis-${index}`,
                order: 6,
                datalabels: {
                  display: true,
                  align: "top",
                  anchor: "end",
                  formatter: (value) => value.y.toFixed(2),
                  font: {
                    weight: "bold",
                  },
                  color: "black",
                  offset: -5,
                },
              };
            })
            .filter(Boolean)
        : []),
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            let xValue = context.raw.x;
            const yValue = context.raw.y;

            // Adjust xValue based on the "showXAxisFromZero" setting
            if (showXAxisFromZero) {
              xValue = xValue - range[0];
            }

            return (
              label + ": (" + xValue.toFixed(2) + ", " + yValue.toFixed(2) + ")"
            );
          },
        },
      },
      legend: {
        display: true,
        onClick: (e, legendItem, legend) => {
          const datasetIndex = legendItem.datasetIndex;
          const chart = legend.chart;
          const dataset = chart.data.datasets[datasetIndex];
          dataset.hidden = !dataset.hidden;
          chart.update();
        },
      },
      zoom: {
        pan: { enabled: true, mode: "xy" },
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: "xy",
        },
      },
      datalabels: {
        display: (context) => context.dataset.label.includes("Peaks"),
        align: "top",
        anchor: "end",
        formatter: (value) => value.y.toFixed(2),
        font: {
          weight: "bold",
        },
        color: "black",
        offset: -5,
      },
    },
    interaction: { mode: "nearest", intersect: false },
    scales: {
      x: {
        type: "linear",
        title: { display: true, text: xAxis },
        min:
          displayData.length > 0
            ? parseFloat(displayData[0][xAxis])
            : undefined,
        max:
          displayData.length > 0
            ? parseFloat(displayData[displayData.length - 1][xAxis])
            : undefined,
        ticks: {
          stepSize: 60, // Set tick interval to 60 seconds
          autoSkip: false, // Disable automatic skipping of ticks
          callback: (value) => (showXAxisFromZero ? value - range[0] : value),
        },
      },
      ...yAxis.reduce((acc, yCol, index) => {
        acc[`y-axis-${index}`] = {
          type: "linear",
          position: index % 2 === 0 ? "left" : "right",
          title: { display: true, text: yCol.label },
          grid: { drawOnChartArea: index === 0 },
        };
        return acc;
      }, {}),
    },
  };

  ////Linear Regression Save and Tables.
  const saveLinearRegression = () => {
    const newLinearData = yAxis
      .map((yCol) => {
        const regressionData = regressionStats[yCol.label]?.linear;
        if (regressionData) {
          return {
            id: `${Date.now()}-${Math.random()}`, // More unique identifier
            variable: yCol.label,
            startTime: Number(displayData[0][xAxis]).toFixed(2),
            endTime: Number(displayData[displayData.length - 1][xAxis]).toFixed(
              2
            ),
            slope: Number(regressionData.slope).toFixed(4),
            rSquared: Number(regressionData.rSquared).toFixed(4),
          };
        }
        return null;
      })
      .filter(Boolean);

    console.log("New Linear Data:", newLinearData); // Add this for debugging
    if (newLinearData.length > 0) {
      setLinearRegressions((prev) => [...prev, ...newLinearData]);
    }
  };

  // Add function to handle cell editing
  const handleCellEdit = (id, field, value) => {
    setLinearRegressions((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  // Add function to export CSV
  const exportToCSV = () => {
    const headers = [
      "Variable",
      "Start Time",
      "End Time",
      "Slope",
      "R Squared",
    ];
    const csvData = linearRegressions.map((row) =>
      [row.variable, row.startTime, row.endTime, row.slope, row.rSquared].join(
        ","
      )
    );

    const csvContent = [headers.join(","), ...csvData].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "linear_regressions.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Add function to delete a row
  const deleteRow = (id) => {
    setLinearRegressions((prevData) => prevData.filter((row) => row.id !== id));
  };

  return (
    <div className="data-analysis-container">
      <h2>Data Analysis</h2>

      <div className="selection-row">
        <Select
          options={Object.keys(dataFrames).map((name) => ({
            value: name,
            label: name,
          }))}
          onChange={(option) => setSelectedDataFrame(option.value)}
          placeholder="Select DataFrame"
          styles={{
            container: (base) => ({ ...base, width: "100%" }),
            control: (base) => ({
              ...base,
              minHeight: "48px",
              fontSize: "16px",
            }),
          }}
        />
        <Select
          options={options}
          value={options.find((opt) => opt.value === xAxis)}
          placeholder="Select X-axis"
          onChange={(option) => setXAxis(option.value)}
          styles={{
            container: (base) => ({ ...base, width: "100%" }),
            control: (base) => ({
              ...base,
              minHeight: "48px",
              fontSize: "16px",
            }),
          }}
        />
        <Select
          options={options}
          isMulti
          value={yAxis}
          placeholder="Select Y-axis"
          onChange={(selectedOptions) => setYAxis(selectedOptions)}
          styles={{
            container: (base) => ({ ...base, width: "100%" }),
            control: (base) => ({
              ...base,
              minHeight: "48px",
              fontSize: "16px",
            }),
          }}
        />
      </div>

      {/* Checkbox and controls */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={showLinearRegression}
              onChange={() => setShowLinearRegression(!showLinearRegression)}
            />
          }
          label="Show Linear Regression"
        />
        {showLinearRegression && (
          <Button
            variant="contained"
            color="primary"
            onClick={saveLinearRegression}
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          >
            Save
          </Button>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={showPolynomialRegression}
              onChange={() =>
                setShowPolynomialRegression(!showPolynomialRegression)
              }
            />
          }
          label="Polynomial Regression"
        />
        {showPolynomialRegression && (
          <TextField
            label="Degree"
            type="number"
            value={polynomialDegree}
            onChange={(e) => setPolynomialDegree(parseInt(e.target.value))}
            InputProps={{ inputProps: { min: 1 } }}
            style={{ width: "80px", marginLeft: "1rem" }}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={showMovingAverage}
              onChange={() => setShowMovingAverage(!showMovingAverage)}
            />
          }
          label="Show Moving Average"
        />
        {showMovingAverage && (
          <Slider
            value={movingAverageWindow}
            onChange={(e, newValue) => setMovingAverageWindow(newValue)}
            valueLabelDisplay="auto"
            min={1}
            max={100}
            style={{ width: "200px", marginLeft: "1rem" }}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={showKinetics}
              onChange={() => setShowKinetics(!showKinetics)}
            />
          }
          label="Kinetics"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showSigmoidalFit}
              onChange={() => setShowSigmoidalFit(!showSigmoidalFit)}
            />
          }
          label="Sigmoidal Fit"
        />
        {showSigmoidalFit && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleStoreSigmoidalData}
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          >
            Save Sigmoidal
          </Button>
        )}
      </Box>

      {/* Chart */}
      <div className="chart-container">
        {xAxis && yAxis && yAxis.length > 0 ? (
          <ChartComponent data={chartData} options={chartOptions} />
        ) : (
          <p>Please select both X and Y axes to display the chart.</p>
        )}
      </div>

      {/* Range Slider */}
      <div className="range-slider">
        <Slider
          value={range}
          onChange={handleRangeChange}
          valueLabelDisplay="auto"
          min={0}
          max={originalData.length - 1}
          style={{ width: "100%" }}
        />
      </div>

      {/* Additional Controls */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={showXAxisFromZero}
              onChange={() => setShowXAxisFromZero(!showXAxisFromZero)}
            />
          }
          label="0 Ticks"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showPeakDetection}
              onChange={() => setShowPeakDetection(!showPeakDetection)}
            />
          }
          label="Detect Peaks"
        />
        {showPeakDetection && (
          <TextField
            label="Peak Cutoff Value"
            type="number"
            value={peakCutoff}
            onChange={(e) => setPeakCutoff(Number(e.target.value))}
            style={{ width: "150px", marginLeft: "1rem", marginRight: "1rem" }}
          />
        )}

        <TextField
          label="Start"
          type="number"
          value={startValue}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (!isNaN(value) && value >= 0 && value < endValue) {
              handleFineTuneChange(0, value);
            } else {
              setStartValue(value);
            }
          }}
          style={{ width: "100px", marginRight: "1rem" }}
        />

        <TextField
          label="End"
          type="number"
          value={endValue}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (
              !isNaN(value) &&
              value > startValue &&
              value <= originalData.length - 1
            ) {
              handleFineTuneChange(1, value);
            } else {
              setEndValue(value);
            }
          }}
          style={{ width: "100px" }}
        />

        {/* Save Region Button */}
        <button
          onClick={() => {
            setRegionsOfInterest([
              ...regionsOfInterest,
              {
                start: startValue,
                end: endValue,
                label: `Region ${regionsOfInterest.length + 1}`,
              },
            ]);
          }}
          style={{ marginLeft: "1rem", marginRight: "1rem" }}
        >
          Save Region
        </button>

        {/* Dropdown to select regions */}
        <Select
          options={regionsOfInterest.map((region, index) => ({
            value: index,
            label: region.label,
          }))}
          onChange={(option) => {
            const region = regionsOfInterest[option.value];
            setRange([region.start, region.end]);
          }}
          placeholder="Select Region"
          isDisabled={regionsOfInterest.length === 0}
          styles={{
            container: (base) => ({
              ...base,
              width: "200px",
              marginLeft: "1rem",
            }),
            control: (base) => ({
              ...base,
              minHeight: "48px",
              fontSize: "16px",
            }),
          }}
        />
      </Box>

      {/* Statistics Display */}
      <div style={{ marginTop: "1rem" }}>
        {yAxis.map((yCol) => (
          <div key={yCol.label}>
            <p>
              <strong>{yCol.label} - Max:</strong> {stats[yCol.label]?.max} |{" "}
              <strong>Min:</strong> {stats[yCol.label]?.min} |{" "}
              <strong>Mean:</strong> {stats[yCol.label]?.mean} |{" "}
              <strong>Difference:</strong> {stats[yCol.label]?.difference}|{" "}
              <strong>MAD:</strong> {stats[yCol.label]?.mad}
            </p>

            {/* Linear Regression Stats */}
            {showLinearRegression && regressionStats[yCol.label]?.linear && (
              <p>
                <strong>Linear Regression:</strong> | <strong>Slope:</strong>{" "}
                {regressionStats[yCol.label]?.linear?.slope} |{" "}
                <strong>Intercept:</strong>{" "}
                {regressionStats[yCol.label]?.linear?.intercept} |{" "}
                <strong>R² (Linear):</strong>{" "}
                {regressionStats[yCol.label]?.linear?.rSquared}
              </p>
            )}

            {/* Polynomial Regression Stats */}
            {showPolynomialRegression &&
              regressionStats[yCol.label]?.polynomial && (
                <p>
                  <strong>Polynomial Regression:</strong> |{" "}
                  <strong>R² (Polynomial):</strong>{" "}
                  {regressionStats[yCol.label]?.polynomial?.rSquared} |{" "}
                  <strong>AUC:</strong>{" "}
                  {regressionStats[yCol.label]?.polynomial?.auc} |{" "}
                  <strong>Coefficients:</strong> [
                  {regressionStats[yCol.label]?.polynomial?.coefficients.join(
                    ", "
                  )}
                  ]
                </p>
              )}

            {/* Sigmoidal Fit Stats */}
            {showSigmoidalFit && regressionStats[yCol.label]?.sigmoidal && (
              <SigmoidalStatsDisplay
                stats={regressionStats[yCol.label].sigmoidal}
                yAxisLabel={yCol.label}
                startValue={startValue}
                showXAxisFromZero={showXAxisFromZero}
              />
            )}
          </div>
        ))}
      </div>

      {/* Kinetics Cards */}
      {showKinetics && (
        <div className="kinetics-cards-container">
          {yAxis.map((yCol) => (
            <KineticsCard
              key={yCol.label}
              yAxisLabel={yCol.label}
              max={kineticsStats[yCol.label]?.max}
              min={kineticsStats[yCol.label]?.min}
              fastCount={kineticsStats[yCol.label]?.fastCount}
              slowCount={kineticsStats[yCol.label]?.slowCount}
              fastPercentage={kineticsStats[yCol.label]?.fastPercentage}
              slowPercentage={kineticsStats[yCol.label]?.slowPercentage}
            />
          ))}
        </div>
      )}
      {/* Display Metabolic Statistics */}
      {/* Conditionally render Metabolic Card based on selectedDataFrame */}
      {(selectedDataFrame === "cortex" ||
        selectedDataFrame === "Cortex_RT" ||
        selectedDataFrame === "Calibre_Data") &&
        metabolicStats && (
          <Card
            className="metabolic-card"
            style={{ marginTop: "20px", backgroundColor: "lightskyblue" }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Metabolic Statistics
              </Typography>
              {metabolicStats.maxVo2kg && (
                <Typography variant="body1">
                  <strong>Max VO2/kg:</strong>{" "}
                  {metabolicStats.maxVo2kg.toFixed(2)} ml/kg/min at{" "}
                  {metabolicStats.maxVo2kgTime}
                </Typography>
              )}
              {metabolicStats.rerMean && (
                <Typography variant="body1">
                  <strong>Respiratory Exchange Ratio (RER) Mean:</strong>{" "}
                  {metabolicStats.rerMean.toFixed(2)}
                </Typography>
              )}
              {metabolicStats.weirCalc && (
                <Typography variant="body1">
                  <strong>Weir Calculation:</strong> {metabolicStats.weirCalc}
                </Typography>
              )}
              {metabolicStats.weirModified && (
                <Typography variant="body1">
                  <strong>Weir Modified Calculation:</strong>{" "}
                  {metabolicStats.weirModified}
                </Typography>
              )}
              {metabolicStats.ree && (
                <Typography variant="body1">
                  <strong>REE:</strong> {metabolicStats.ree.toFixed(2)}
                </Typography>
              )}
              {/* Calibre-specific statistics */}
              {metabolicStats.vo2Max !== undefined && (
                <Typography variant="body1">
                  <strong>VO2 Max:</strong> {metabolicStats.vo2Max.toFixed(2)}{" "}
                  ml/kg/min at {metabolicStats.vo2MaxTime}
                </Typography>
              )}
              {metabolicStats.bmrCalibreAvg !== undefined && (
                <Typography variant="body1">
                  <strong>BMR Calibre Average:</strong>{" "}
                  {metabolicStats.bmrCalibreAvg.toFixed(2)} kcal/day
                </Typography>
              )}
              {metabolicStats.bmrCalibreHighLow !== undefined && (
                <Typography variant="body1">
                  <strong>BMR Calibre High/Low: </strong>{" "}
                  {metabolicStats.bmrCalibreHighLow.toFixed(2)} kcal/day
                </Typography>
              )}
              {metabolicStats.weirCalcOriginal !== undefined && (
                <Typography variant="body1">
                  <strong>Weir Formula: </strong>{" "}
                  {metabolicStats.weirCalcOriginal.toFixed(2)} kcal/day
                </Typography>
              )}
              {metabolicStats.weirModifiedOriginal !== undefined && (
                <Typography variant="body1">
                  <strong>Weir Modified Formula: </strong>{" "}
                  {metabolicStats.weirModifiedOriginal.toFixed(2)} kcal/day
                </Typography>
              )}
            </CardContent>
          </Card>
        )}

      {/* Display Peak Information */}
      {showPeakDetection && peakInfo.length > 0 ? (
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Detected Peaks Information for Selected Range
            </Typography>
            {peakInfo.map((info, index) => (
              <Typography key={index} variant="body1">
                <strong>Y-Axis:</strong> {info.yAxis} |{" "}
                <strong>Time (s):</strong> {info.time.toFixed(2)} |{" "}
                <strong>Peak Value:</strong> {info.value.toFixed(2)} |{" "}
                <strong>Percentage Change (%):</strong> {info.percentChange} |{" "}
                <strong>Time Difference (s):</strong> {info.timeDifference}
              </Typography>
            ))}
          </CardContent>
        </Card>
      ) : null}

      {linearRegressions.length > 0 && (
        <div style={{ marginTop: "2rem" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Saved Linear Regressions</Typography>
            <Button variant="contained" color="secondary" onClick={exportToCSV}>
              Export CSV
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Slope</TableCell>
                  <TableCell>R²</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linearRegressions.map((row) => (
                  <TableRow key={row.id}>
                    {[
                      "variable",
                      "startTime",
                      "endTime",
                      "slope",
                      "rSquared",
                    ].map((field) => (
                      <TableCell key={field}>
                        {editingCell === `${row.id}-${field}` ? (
                          <TextField
                            value={row[field]}
                            onChange={(e) =>
                              handleCellEdit(row.id, field, e.target.value)
                            }
                            onBlur={() => setEditingCell(null)}
                            autoFocus
                            size="small"
                          />
                        ) : (
                          <div
                            onClick={() => setEditingCell(`${row.id}-${field}`)}
                            style={{ cursor: "pointer" }}
                          >
                            {row[field]}
                          </div>
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => deleteRow(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {sigmoidalData.length > 0 && (
        <div style={{ marginTop: "2rem" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Saved Sigmoidal Fits</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportSigmoidalToCSV}
            >
              Export CSV
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Max</TableCell>
                  <TableCell>Min</TableCell>
                  <TableCell>Difference</TableCell>
                  <TableCell>L</TableCell>
                  <TableCell>x₀</TableCell>
                  <TableCell>k</TableCell>
                  <TableCell>R²</TableCell>
                  <TableCell>AUC</TableCell>
                  <TableCell>Tmax</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sigmoidalData.map((row) => (
                  <TableRow key={row.id}>
                    {[
                      "yAxis",
                      "startTime",
                      "endTime",
                      "max",
                      "min",
                      "difference",
                      "L",
                      "x0",
                      "k",
                      "rSquared",
                      "auc",
                      "tmax",
                      "model",
                    ].map((field) => (
                      <TableCell key={field}>
                        {editingSigmoidalCell === `${row.id}-${field}` ? (
                          <TextField
                            value={row[field]}
                            onChange={(e) =>
                              handleSigmoidalCellEdit(
                                row.id,
                                field,
                                e.target.value
                              )
                            }
                            onBlur={() => setEditingSigmoidalCell(null)}
                            autoFocus
                            size="small"
                          />
                        ) : (
                          <div
                            onClick={() =>
                              setEditingSigmoidalCell(`${row.id}-${field}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {typeof row[field] === "number"
                              ? row[field].toFixed(4)
                              : row[field]}
                          </div>
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => deleteSigmoidalRow(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default DataAnalysis;
