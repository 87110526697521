// // src/utils/regressionUtils.js
// import { SLR } from "ml-regression";
// import { PolynomialRegression } from "ml-regression-polynomial";
// import { levenbergMarquardt } from "ml-levenberg-marquardt";

// /**
//  * Perform linear regression on the given data.
//  * @param {Array<number>} xValues - Array of x-axis values.
//  * @param {Array<number>} yValues - Array of y-axis values.
//  * @returns {Object} - Linear regression results with slope, intercept, rSquared, and predictions.
//  */
// export function calculateLinearRegression(xValues, yValues) {
//   if (xValues.length === 0 || yValues.length === 0) return {};

//   const regression = new SLR(xValues, yValues);
//   const slope = regression.slope.toFixed(4);
//   const intercept = regression.intercept.toFixed(4);
//   const predictions = xValues.map((x) => regression.predict(x));
//   const rSquared = calculateR2(yValues, predictions).toFixed(4);

//   return { slope, intercept, rSquared, predictions };
// }

// /**
//  * Perform polynomial regression on the given data.
//  * @param {Array<number>} xValues - Array of x-axis values.
//  * @param {Array<number>} yValues - Array of y-axis values.
//  * @param {number} degree - Degree of the polynomial.
//  * @returns {Object} - Polynomial regression results with coefficients, rSquared, predictions, trend, and auc.
//  */
// export function calculatePolynomialRegression(xValues, yValues, degree) {
//   if (xValues.length === 0 || yValues.length === 0) return {};

//   const regression = new PolynomialRegression(xValues, yValues, degree);
//   const predictions = xValues.map((x) => regression.predict(x));
//   const rSquared = calculateR2(yValues, predictions).toFixed(4);
//   const coefficients = regression.coefficients.map((coef) => coef.toFixed(4));
//   const trend =
//     predictions[predictions.length - 1] > predictions[0] ? "up" : "down";
//   const auc = calculateAUC(
//     regression,
//     xValues[0],
//     xValues[xValues.length - 1]
//   ).toFixed(4);

//   return { rSquared, coefficients, predictions, trend, auc };
// }

// /**
//  * Perform a sigmoidal fit on the given data.
//  * @param {Array<number>} xValues - Array of x-axis values.
//  * @param {Array<number>} yValues - Array of y-axis values.
//  * @returns {Object} - Sigmoidal fit results with parameters, rSquared, and predictions.
//  */
// export function calculateSigmoidalFit(xValues, yValues) {
//   const maxY = Math.max(...yValues);
//   const minY = Math.min(...yValues);

//   // Initial guess for parameters
//   const initialValues = [
//     maxY / 3,
//     xValues[Math.floor(xValues.length / 4)],
//     1,
//     maxY / 3,
//     xValues[Math.floor(xValues.length / 2)],
//     1,
//     maxY / 3,
//     xValues[Math.floor((3 * xValues.length) / 4)],
//     1,
//   ];

//   const options = {
//     damping: 0.5,
//     initialValues,
//     gradientDifference: 0.01,
//     maxIterations: 1000,
//     errorTolerance: 1e-4,
//   };

//   const parameterizedFunction = (parameters) => (x) => {
//     const [L1, x01, k1, L2, x02, k2, L3, x03, k3] = parameters;
//     return (
//       L1 / (1 + Math.exp(-k1 * (x - x01))) +
//       L2 / (1 + Math.exp(-k2 * (x - x02))) +
//       L3 / (1 + Math.exp(-k3 * (x - x03)))
//     );
//   };

//   const dataForLM = { x: xValues, y: yValues };

//   try {
//     const fittedParams = levenbergMarquardt(
//       dataForLM,
//       parameterizedFunction,
//       options
//     );
//     const parameterValues = fittedParams.parameterValues;
//     const predictions = xValues.map((x) =>
//       parameterizedFunction(parameterValues)(x)
//     );
//     const rSquared = calculateR2(yValues, predictions).toFixed(4);

//     return {
//       parameters: parameterValues.map((p) => p.toFixed(4)),
//       rSquared,
//       predictions,
//     };
//   } catch (error) {
//     console.error("Sigmoidal Fit Error:", error);
//     return { parameters: [], rSquared: "N/A", predictions: [] };
//   }
// }

// /**
//  * Calculate the R-squared value for a regression.
//  * @param {Array<number>} yValues - Original y-axis values.
//  * @param {Array<number>} regressionPoints - Predicted y-axis values from regression.
//  * @returns {number} - R-squared value.
//  */
// function calculateR2(yValues, regressionPoints) {
//   const meanY = yValues.reduce((a, b) => a + b, 0) / yValues.length;
//   const ssTot = yValues.reduce((sum, y) => sum + Math.pow(y - meanY, 2), 0);
//   const ssRes = yValues.reduce(
//     (sum, y, i) => sum + Math.pow(y - regressionPoints[i], 2),
//     0
//   );
//   return 1 - ssRes / ssTot;
// }

// /**
//  * Calculate the area under the curve (AUC) for a polynomial regression.
//  * @param {PolynomialRegression} regression - Polynomial regression object.
//  * @param {number} startX - Starting x-value for integration.
//  * @param {number} endX - Ending x-value for integration.
//  * @returns {number} - Calculated AUC value.
//  */
// function calculateAUC(regression, startX, endX) {
//   const coeffs = regression.coefficients;
//   const degree = coeffs.length - 1;

//   // Calculate the antiderivative of the polynomial
//   const antiderivativeCoeffs = coeffs.map(
//     (coef, index) => coef / (degree - index + 1)
//   );
//   const antiderivative = (x) =>
//     antiderivativeCoeffs.reduce(
//       (sum, coef, index) => sum + coef * Math.pow(x, degree - index + 1),
//       0
//     );

//   return antiderivative(endX) - antiderivative(startX);
// }

// src/utils/regressionUtils.js

// src/utils/regressionUtils.js
//

import { SLR } from "ml-regression";
import { PolynomialRegression } from "ml-regression-polynomial";
import { levenbergMarquardt } from "ml-levenberg-marquardt";

/**
 * Calculate R-squared value
 */
function calculateR2(yActual, yPredicted) {
  const meanY = yActual.reduce((a, b) => a + b, 0) / yActual.length;
  const ssTot = yActual.reduce((sum, y) => sum + Math.pow(y - meanY, 2), 0);
  const ssRes = yActual.reduce(
    (sum, y, i) => sum + Math.pow(y - yPredicted[i], 2),
    0
  );
  return 1 - ssRes / ssTot;
}

/**
 * Calculate AUC using trapezoidal method
 */
function calculateAUC(x, y) {
  let auc = 0;
  for (let i = 1; i < x.length; i++) {
    auc += ((x[i] - x[i - 1]) * (y[i] + y[i - 1])) / 2;
  }
  return auc;
}

/**
 * Calculate moving average
 */
export function calculateMovingAverage(data, windowSize) {
  return data.map((_, index) => {
    const start = Math.max(0, index - Math.floor(windowSize / 2));
    const end = Math.min(data.length, index + Math.floor(windowSize / 2) + 1);
    const windowData = data.slice(start, end);
    return windowData.reduce((sum, val) => sum + val, 0) / windowData.length;
  });
}

/**
 * Perform linear regression
 */
export function calculateLinearRegression(xValues, yValues) {
  if (xValues.length === 0 || yValues.length === 0) return {};

  try {
    const regression = new SLR(xValues, yValues);
    const slope = regression.slope.toFixed(4);
    const intercept = regression.intercept.toFixed(4);
    const predictions = xValues.map((x) => regression.predict(x));
    const rSquared = calculateR2(yValues, predictions).toFixed(4);

    return { slope, intercept, rSquared, predictions };
  } catch (error) {
    console.error("Linear Regression Error:", error);
    return { slope: "N/A", intercept: "N/A", rSquared: "N/A", predictions: [] };
  }
}

/**
 * Perform polynomial regression
 */
export function calculatePolynomialRegression(xValues, yValues, degree) {
  if (xValues.length === 0 || yValues.length === 0) return {};

  try {
    const regression = new PolynomialRegression(xValues, yValues, degree);
    const predictions = xValues.map((x) => regression.predict(x));
    const rSquared = calculateR2(yValues, predictions).toFixed(4);
    const coefficients = regression.coefficients.map((coef) => coef.toFixed(4));
    const trend =
      predictions[predictions.length - 1] > predictions[0] ? "up" : "down";
    const auc = calculateAUC(xValues, predictions).toFixed(4);

    return { rSquared, coefficients, predictions, trend, auc };
  } catch (error) {
    console.error("Polynomial Regression Error:", error);
    return {
      rSquared: "N/A",
      coefficients: [],
      predictions: [],
      trend: "unknown",
      auc: "N/A",
    };
  }
}

/**
 * Calculate sigmoidal fit with comprehensive sport science metrics
 */
export function calculateSigmoidalFit(xValues, yValues) {
  const maxY = Math.max(...yValues);
  const minY = Math.min(...yValues);
  const range = maxY - minY;

  const initialValues = [
    range / 3,
    xValues[Math.floor(xValues.length / 4)],
    1,
    range / 3,
    xValues[Math.floor(xValues.length / 2)],
    1,
    range / 3,
    xValues[Math.floor((3 * xValues.length) / 4)],
    1,
  ];

  const options = {
    damping: 0.5,
    initialValues,
    gradientDifference: 0.01,
    maxIterations: 1000,
    errorTolerance: 1e-4,
  };

  const parameterizedFunction = (parameters) => (x) => {
    const [L1, x01, k1, L2, x02, k2, L3, x03, k3] = parameters;
    return (
      L1 / (1 + Math.exp(-k1 * (x - x01))) +
      L2 / (1 + Math.exp(-k2 * (x - x02))) +
      L3 / (1 + Math.exp(-k3 * (x - x03)))
    );
  };

  try {
    const dataForLM = { x: xValues, y: yValues };
    const fittedParams = levenbergMarquardt(
      dataForLM,
      parameterizedFunction,
      options
    );
    const parameterValues = fittedParams.parameterValues;
    const predictions = xValues.map((x) =>
      parameterizedFunction(parameterValues)(x)
    );

    // Extract basic parameters
    const [L1, x01, k1, L2, x02, k2, L3, x03, k3] = parameterValues;

    // Calculate total L, effective x0 and k for DataAnalysis component
    const L = L1 + L2 + L3; // Total amplitude
    const x0 = (x01 * L1 + x02 * L2 + x03 * L3) / L; // Weighted average of time constants
    const k = (k1 * L1 + k2 * L2 + k3 * L3) / L; // Weighted average of rate constants

    // Calculate Area Metrics
    const totalAUC = calculateAUC(xValues, predictions);
    const baselineAUC = calculateAUC(
      xValues,
      xValues.map(() => minY)
    );
    const netAUC = totalAUC - baselineAUC;

    // Calculate time segments
    const quarterPoints = [0.25, 0.5, 0.75, 1.0].map((point) => {
      const targetY = minY + (maxY - minY) * point;
      return (
        xValues[predictions.findIndex((y) => y >= targetY)] ||
        xValues[xValues.length - 1]
      );
    });

    // Calculate rates of change
    const quarterRates = quarterPoints
      .map((point, i) => {
        if (i === 0) return null;
        const prevPoint = quarterPoints[i - 1];
        return (point - prevPoint) / (quarterPoints[i] - quarterPoints[i - 1]);
      })
      .filter((rate) => rate !== null);

    // Calculate velocities and accelerations
    const velocities = predictions.map((y, i) =>
      i === 0 ? 0 : (y - predictions[i - 1]) / (xValues[i] - xValues[i - 1])
    );

    const accelerations = velocities.map((v, i) =>
      i === 0 ? 0 : (v - velocities[i - 1]) / (xValues[i] - xValues[i - 1])
    );

    // Find tmax (time at maximum rate of change)
    const maxVelocityIndex = velocities.indexOf(Math.max(...velocities));
    const tmax = xValues[maxVelocityIndex];

    // Calculate R-squared and RMSE
    const rSquared = calculateR2(yValues, predictions);
    const residuals = yValues.map((y, i) => y - predictions[i]);
    const rmse = Math.sqrt(
      residuals.reduce((sum, r) => sum + r * r, 0) / residuals.length
    );

    return {
      // Basic parameters for DataAnalysis component
      L: L,
      x0: x0,
      k: k,
      rSquared: rSquared,
      auc: totalAUC,
      tmax: tmax,
      max: maxY,
      min: minY,

      // Detailed parameters and metrics
      parameters: {
        phaseOne: { amplitude: L1, timeConstant: x01, rateConstant: k1 },
        phaseTwo: { amplitude: L2, timeConstant: x02, rateConstant: k2 },
        phaseThree: { amplitude: L3, timeConstant: x03, rateConstant: k3 },
      },

      areaMetrics: {
        totalAUC: totalAUC.toFixed(4),
        baselineAUC: baselineAUC.toFixed(4),
        netAUC: netAUC.toFixed(4),
        areaRatio: (netAUC / totalAUC).toFixed(4),
      },

      timeMetrics: {
        quarterPoints: quarterPoints.map((x) => x.toFixed(4)),
        timeToQuarters: quarterPoints.map((point, i) =>
          i === 0 ? point : (point - quarterPoints[i - 1]).toFixed(4)
        ),
        totalResponseTime: (quarterPoints[3] - quarterPoints[0]).toFixed(4),
        meanResponseRate: (
          (maxY - minY) /
          (quarterPoints[3] - quarterPoints[0])
        ).toFixed(4),
      },

      rateMetrics: {
        quarterRates: quarterRates.map((rate) => rate.toFixed(4)),
        peakRate: Math.max(...velocities).toFixed(4),
        meanRate: (
          velocities.reduce((a, b) => a + b, 0) / velocities.length
        ).toFixed(4),
        peakAcceleration: Math.max(...accelerations).toFixed(4),
      },

      modelFit: {
        rSquared: rSquared.toFixed(4),
        rmse: rmse.toFixed(4),
        predictions: predictions,
        displayData: xValues.map((x, i) => ({
          originalTime: x,
          value: predictions[i],
        })),
      },

      magnitudeMetrics: {
        baseline: minY.toFixed(4),
        peak: maxY.toFixed(4),
        totalAmplitude: (maxY - minY).toFixed(4),
        responsePercentage: (((maxY - minY) / minY) * 100).toFixed(2),
      },
    };
  } catch (error) {
    console.error("Sigmoidal Fit Error:", error);
    return {
      L: 0,
      x0: 0,
      k: 0,
      rSquared: 0,
      auc: 0,
      tmax: 0,
      parameters: {},
      areaMetrics: {},
      timeMetrics: {},
      rateMetrics: {},
      modelFit: { predictions: [], displayData: [] },
      magnitudeMetrics: {},
    };
  }
}
