// import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Divider,
} from "@mui/material";

const SigmoidalStatsDisplay = ({
  stats,
  yAxisLabel,
  startValue,
  showXAxisFromZero,
}) => {
  if (!stats || Object.keys(stats).length === 0) {
    return null;
  }

  const formatNumber = (value, decimals = 2) => {
    if (value === null || value === undefined) return "N/A";
    const number = Number(value);
    if (isNaN(number)) return "N/A";
    return number.toFixed(decimals);
  };

  return (
    <Card sx={{ mt: 2, mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Sigmoidal Analysis for {yAxisLabel}
        </Typography>

        {/* Basic Parameters */}
        <Box sx={{ mb: 2, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Basic Parameters
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>L: {formatNumber(stats?.L, 4)}</Typography>
              <Typography>x₀: {formatNumber(stats?.x0, 4)}</Typography>
              <Typography>k: {formatNumber(stats?.k, 4)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Max: {formatNumber(stats?.max, 4)}</Typography>
              <Typography>Min: {formatNumber(stats?.min, 4)}</Typography>
              <Typography>
                R²: {formatNumber(stats?.modelFit?.rSquared, 4)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Time Reference */}
        <Box sx={{ mb: 2, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Time Reference
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                Start Time: {formatNumber(stats?.timeReference?.start)}s
              </Typography>
              <Typography>
                End Time: {formatNumber(stats?.timeReference?.end)}s
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Duration: {formatNumber(stats?.timeReference?.duration)}s
              </Typography>
              <Typography>
                Mode: {showXAxisFromZero ? "Time from 0" : "Absolute Time"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* Time Course Analysis */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Time Course Analysis
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Time to 25%</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.timeMetrics?.quarterPoints?.[0])} s
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Time to 50%</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.timeMetrics?.quarterPoints?.[1])} s
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Time to 75%</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.timeMetrics?.quarterPoints?.[2])} s
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Response Time</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.timeMetrics?.totalResponseTime)} s
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Area Analysis */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Area Analysis
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Total AUC</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.areaMetrics?.totalAUC)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net AUC</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.areaMetrics?.netAUC)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Area Ratio</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.areaMetrics?.areaRatio)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Rate Analysis */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Rate Analysis
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Peak Rate</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.rateMetrics?.peakRate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mean Rate</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.rateMetrics?.meanRate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Peak Acceleration</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.rateMetrics?.peakAcceleration)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Model Fit & Magnitude */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Model Fit & Magnitude
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>R²</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.modelFit?.rSquared, 4)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>RMSE</TableCell>
                    <TableCell align="right">
                      {formatNumber(stats?.modelFit?.rmse, 4)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Response %</TableCell>
                    <TableCell align="right">
                      {formatNumber(
                        stats?.magnitudeMetrics?.responsePercentage,
                        2
                      )}
                      %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SigmoidalStatsDisplay;
